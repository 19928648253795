
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import ApiService from '@/services/ApiService'
import { Plan, Team } from '@/types/team'

export default defineComponent({
  name: 'BillingPanel',
  setup() {
    const store = useStore()
    const activeTeam = computed<Team>(() => store.getters['teams/getActiveTeam'])
    const activeMembers = computed<number>(() => store.getters['teams/getActiveTeamMembers'].length)
    const pendingInvites = computed<number>(() => store.getters['teams/getActiveTeamPendingInvites'].length)

    async function activateFreePlan() {
      await ApiService.sendRequest('subscriptions', {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify({
          plan: Plan.FREE
        })
      })
      setTimeout(() => {
        store.dispatch('teams/getTeam', { teamId: activeTeam.value.teamId })
      }, 1000)
    }

    async function activateTeamPlan() {
      const response = await ApiService.sendRequest('subscriptions', {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify({
          plan: Plan.TEAM
        })
      })
      const redirectUrl = await response.json()
      window.location.href = redirectUrl
    }

    async function openCustomerPortal() {
      const response = await ApiService.sendRequest('subscriptions/customer-portal', {
        method: 'POST',
        requiresAuth: true
      })
      const redirectUrl = await response.json()
      window.location.href = redirectUrl
    }

    return {
      activeTeam,
      activateFreePlan,
      activateTeamPlan,
      openCustomerPortal,
      activeMembers,
      pendingInvites,
      Plan
    }
  }
})
