
import router from '@/router'
import ToastNotificationService from '@/services/ToastNotificationService'
import { useStore } from '@/store'
import { computed, defineComponent, onMounted, ref } from 'vue'
import ModalHandlerService from '@/services/ModalHandlerService'
import DisableSlackModal from '@feature/user-settings/team/DisableSlackModal.vue'
import DisableJiraModal from '@feature/user-settings/team/DisableJiraModal.vue'

import AppsPanelAppSkeleton from '@skeleton/feature/user-settings/team/AppsPanelApp.vue'
import FadeTransition from '@transition/FadeTransition.vue'

const SLACK_OAUTH_URI = 'https://slack.com/oauth/v2/authorize'
const SLACK_CLIENT_ID = '2488479186755.3252170369683'
const SLACK_SCOPES = 'channels:read,chat:write,chat:write.customize,chat:write.public,links:read,links:write'

const JIRA_OAUTH_URI = 'https://auth.atlassian.com/authorize?audience=api.atlassian.com&response_type=code&prompt=consent'
const JIRA_SCOPES = 'read:jira-work offline_access'

export default defineComponent({
  name: 'AppsPanel',
  components: {
    DisableSlackModal,
    DisableJiraModal,
    AppsPanelAppSkeleton,
    FadeTransition
  },
  setup() {
    const store = useStore()

    const integrationSettingsLoaded = computed(() => store.getters['integrations/isIntegrationSettingsLoaded'])
    const slackSettings = computed(() => store.getters['integrations/getSlackSettings'])
    const isAddingToSlack = ref(false)
    const isDisconnectingFromSlack = ref(false)
    const jiraSettings = computed(() => store.getters['integrations/getJiraSettings'])
    const isAddingToJira = ref(false)
    const isDisconnectingFromJira = ref(false)

    function addToSlack() {
      window.location.href = `${SLACK_OAUTH_URI}?client_id=${SLACK_CLIENT_ID}&scope=${SLACK_SCOPES}&redirect_uri=${process.env.VUE_APP_SLACK_OAUTH_REDIRECT_URL}&state=slack`
    }

    function addToJira() {
      window.location.href = `${JIRA_OAUTH_URI}&client_id=${process.env.VUE_APP_JIRA_OAUTH_CLIENTID}&scope=${JIRA_SCOPES}&redirect_uri=${process.env.VUE_APP_JIRA_OAUTH_REDIRECT_URL}&state=${process.env.VUE_APP_JIRA_OAUTH_STATE}`
    }

    onMounted(async () => {
      // Check if we're completing the Slack OAuth integration
      if (router.currentRoute.value.query.state?.includes('slack') && router.currentRoute.value.query.code) {
        await associateSlackToTeam()
      }

      // Check if we're completing the JIRA integration
      if (router.currentRoute.value.query.state?.includes('jira') && router.currentRoute.value.query.code) {
        await associateJiraToTeam()
      }
    })

    async function associateSlackToTeam() {
      isAddingToSlack.value = true
      try {
        await store.dispatch('integrations/associateTeamSlackToken', {
          code: router.currentRoute.value.query.code,
          teamId: store.getters['user/getActiveMembership'].teamId
        })
        ToastNotificationService.push({ type: 'info', message: 'Successfully added Slack.' })
      } catch (error) {
        ToastNotificationService.push({ type: 'error', message: 'Failed to add Slack. Please retry.' })
      } finally {
        isAddingToSlack.value = false
        router.replace({
          query: {},
          hash: router.currentRoute.value.hash
        })
      }
    }

    async function associateJiraToTeam() {
      isAddingToJira.value = true
      try {
        await store.dispatch('integrations/associateTeamJiraToken', {
          code: router.currentRoute.value.query.code,
          teamId: store.getters['user/getActiveMembership'].teamId
        })
        ToastNotificationService.push({ type: 'info', message: 'Successfully added Jira.' })
      } catch (error) {
        ToastNotificationService.push({ type: 'error', message: 'Failed to add Jira. Please retry.' })
      } finally {
        isAddingToJira.value = false
        router.replace({
          query: {},
          hash: router.currentRoute.value.hash
        })
      }
    }

    async function disconnectSlack() {
      try {
        isDisconnectingFromSlack.value = true
        await store.dispatch('integrations/disconnectSlack', { teamId: store.getters['user/getActiveMembership'].teamId })
        ToastNotificationService.push({ type: 'info', message: 'Successfully updated settings' })
      } catch (error) {
        if (error instanceof Error) {
          ToastNotificationService.push({ type: 'error', message: error.message })
        }
      } finally {
        isDisconnectingFromSlack.value = false
        ModalHandlerService.close()
      }
    }

    async function disconnectJira() {
      try {
        isDisconnectingFromJira.value = true
        await store.dispatch('integrations/disconnectJira', { teamId: store.getters['user/getActiveMembership'].teamId })
        ToastNotificationService.push({ type: 'info', message: 'Successfully updated settings' })
      } catch (error) {
        if (error instanceof Error) {
          ToastNotificationService.push({ type: 'error', message: error.message })
        }
      } finally {
        isDisconnectingFromJira.value = false
        ModalHandlerService.close()
      }
    }

    return {
      integrationSettingsLoaded,
      addToSlack,
      addToJira,
      isAddingToSlack,
      isAddingToJira,
      slackSettings,
      jiraSettings,
      disconnectSlack,
      disconnectJira,
      isDisconnectingFromSlack,
      isDisconnectingFromJira,
      ModalHandlerService
    }
  }
})
