
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'UserTable',
  props: {
    headingLeft: {
      type: String,
      required: false
    },
    headingRight: {
      type: String,
      required: false
    },
    headingMiddle: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const oneColumn = computed(() => !props.headingMiddle && !props.headingRight)

    return {
      oneColumn
    }
  }
})
