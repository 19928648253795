import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-tooltip__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-tooltip", { 'app-tooltip--visible': _ctx.visible }])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _renderSlot(_ctx.$slots, "default", {
      visibility: { update: _ctx.updateVisibility }
    })
  ], 2))
}