
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store'
import ApiService from '@/services/ApiService'
import { Plan, Team } from '@/types/team'

export default defineComponent({
  name: 'PlansPanel',
  setup() {
    const store = useStore()
    const activeTeam = computed<Team>(() => store.getters['teams/getActiveTeam'])
    const activeMembers = computed<number>(() => store.getters['teams/getActiveTeamMembers'].length)
    const canSwitchToFree = computed<boolean>(() => store.getters['teams/getActiveTeamPendingInvites'].length + activeMembers.value <= 5)
    const loading = ref(false)

    async function activateFreePlan() {
      loading.value = true
      await ApiService.sendRequest('subscriptions', {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify({
          plan: Plan.FREE
        })
      })
      setTimeout(async () => {
        await store.dispatch('teams/getTeam', { teamId: activeTeam.value.teamId })
        loading.value = false
      }, 2000)
    }

    async function activateTeamPlan() {
      loading.value = true
      const response = await ApiService.sendRequest('subscriptions', {
        method: 'POST',
        requiresAuth: true,
        body: JSON.stringify({
          plan: Plan.TEAM
        })
      })
      const redirectUrl = await response.json()
      loading.value = false
      window.location.href = redirectUrl
    }

    return {
      activeTeam,
      activateFreePlan,
      activateTeamPlan,
      activeMembers,
      canSwitchToFree,
      Plan,
      loading
    }
  }
})
