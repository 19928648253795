
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import { useStore } from '@/store'

import { NOTIFICATION_PERMISSION, WebPushService } from '@/services/WebPushService'

import NotificationSettings, { UpdateNotificationSettingsRequest } from '@/types/notificationSettings'

import AppSwitch from '@global/AppSwitch.vue'
import NotificationsPanelSkeleton from '@skeleton/feature/user-settings/personal/NotificationsPanel.vue'

export default defineComponent({
  name: 'NotificationsPanel',
  components: {
    AppSwitch,
    NotificationsPanelSkeleton
  },
  setup() {
    const store = useStore()
    const notificationSettings = computed<NotificationSettings>(() => store.getters['notifications/getNotificationSettings'])
    const showSoftPrompt = ref('Notification' in window && Notification.permission === NOTIFICATION_PERMISSION.DEFAULT)

    const requestNotificationPermission = async () => {
      const permission = await Notification.requestPermission()
      if (permission === NOTIFICATION_PERMISSION.GRANTED) {
        await WebPushService.instance.updatePushSubscriptions()
      }
      showSoftPrompt.value = false
    }

    const enablePushNotifications = ref(notificationSettings.value?.enablePushNotifications)
    const enableNotificationEmails = ref(notificationSettings.value?.enableNotificationEmails)
    const enableProductEmails = ref(notificationSettings.value?.enableProductEmails)
    const notificationSettingsSynced = ref(false)

    const updateNotificationSettings = () => {
      store.dispatch(
        'notifications/updateNotificationSettings',
        new UpdateNotificationSettingsRequest(
          undefined,
          enablePushNotifications.value,
          enableNotificationEmails.value,
          enableProductEmails.value
        )
      )
    }

    onBeforeMount(async () => {
      if (!notificationSettings.value) {
        await store.dispatch('notifications/getNotificationSettings')
      }

      enablePushNotifications.value = notificationSettings.value.enablePushNotifications
      enableNotificationEmails.value = notificationSettings.value.enableNotificationEmails
      enableProductEmails.value = notificationSettings.value.enableProductEmails
      notificationSettingsSynced.value = true
    })

    onMounted(() => {
      localStorage.setItem('isNotificationPanelVisited', 'true')
    })

    return {
      showSoftPrompt,
      requestNotificationPermission,
      enablePushNotifications,
      enableNotificationEmails,
      enableProductEmails,
      notificationSettingsSynced,
      updateNotificationSettings
    }
  }
})
