import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "user-table" }
const _hoisted_2 = { class: "user-table__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["user-table__header grid", _ctx.oneColumn && 'user-table__header--start'])
    }, [
      (_ctx.headingLeft)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass([
          _ctx.oneColumn ? 'col-12' : 'col-5 col-md-6'
        ])
          }, _toDisplayString(_ctx.headingLeft), 3))
        : _createCommentVNode("", true),
      (_ctx.headingMiddle)
        ? (_openBlock(), _createElementBlock("p", {
            key: 1,
            class: _normalizeClass([
          !_ctx.headingRight ? 'col-6' : 'col-3'
        ])
          }, _toDisplayString(_ctx.headingMiddle), 3))
        : _createCommentVNode("", true),
      (_ctx.headingRight)
        ? (_openBlock(), _createElementBlock("p", {
            key: 2,
            class: _normalizeClass([
          _ctx.headingMiddle && 'col-4 col-md-3',
          !_ctx.headingMiddle && 'col-6 offset-6 offset-md-0',
        ])
          }, _toDisplayString(_ctx.headingRight), 3))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}