
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

import { UserProfile } from '@/types/user'
import { Group } from '@/types/group'

import AppDropdown from '@common/AppDropdown.vue'
import AppBasicDropdown from '@common/AppBasicDropdown.vue'
import AppAvatar from '@common/AppAvatar.vue'

import truncate from '@/filters/truncate'

export default defineComponent({
  name: 'UserItem',
  components: {
    AppAvatar,
    AppDropdown,
    AppBasicDropdown
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    isGroup: {
      type: Boolean,
      default: false
    },
    twoColumnsLayout: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()

    const groups = computed<Group[]>(() => store.getters['groups/getGroups'].filter((group: Group) => group.memberships.some(member => member.userId === props.user.userId)))

    const userProfile = computed(() => new UserProfile(
      props.user.userProfile?.displayName || props.user.email,
      props.user.userProfile?.firstName,
      props.user.userProfile?.lastName,
      props.user.userProfile?.pictureId
    ))

    return {
      userProfile,
      groups,
      truncate
    }
  }
})
