
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { useStore } from '@/store'

import FormValidationService, { shallowValidate } from '@/services/FormValidationService'
import ToastNotificationService from '@/services/ToastNotificationService'
import inputValidationErrors from '@/data/input-validation-errors'

import { IFormErrors } from '@/types/global'
import { SuccessMessages } from '@/data/messages'
import isEmpty from '@/helpers/empty'

export default defineComponent({
  name: 'SecurityPanel',
  setup() {
    const store = useStore()
    const loading = ref(false)

    const fields = reactive({
      password: '',
      currentPassword: '',
      confirmPassword: ''
    })

    const errors = reactive<IFormErrors>({
      fieldEmptyErrors: {},
      specialErrors: {}
    })

    async function changePassword() {
      errors.fieldEmptyErrors = shallowValidate(fields)
      errors.specialErrors = FormValidationService(fields)

      if (fields.confirmPassword.length > 0) {
        if (errors.fieldEmptyErrors.password || errors.specialErrors.password) {
          errors.fieldEmptyErrors = Object.assign(errors.fieldEmptyErrors, { confirmPassword: inputValidationErrors.confirmPassword.format })
        } else if (fields.password !== fields.confirmPassword) {
          errors.fieldEmptyErrors = Object.assign(errors.fieldEmptyErrors, { confirmPassword: inputValidationErrors.confirmPassword.match })
        }
      }

      if (isEmpty(errors.fieldEmptyErrors) && isEmpty(errors.specialErrors)) {
        loading.value = true
        try {
          await store.dispatch('auth/changePassword', {
            oldPassword: fields.currentPassword,
            newPassword: fields.password
          })

          ToastNotificationService.push({
            type: 'info',
            message: SuccessMessages.ACCOUNT_PASSWORD_CHANGED
          })

          fields.currentPassword = ''
          fields.password = ''
          fields.confirmPassword = ''
        } catch (error) {
          errors.specialErrors = Object.assign(errors.specialErrors, {
            aws: { err: error instanceof Error && error.message }
          })
          fields.currentPassword = ''
        } finally {
          loading.value = false
        }
      }
    }

    return {
      ...toRefs(fields),
      ...toRefs(errors),
      changePassword,
      loading,
      ToastNotificationService
    }
  }
})
