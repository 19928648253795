
import { computed, defineComponent, nextTick, ref } from 'vue'
import { useStore } from '@/store'
import { TeamInvite } from '@/store/teamInvite'
import AddMemberModal from '@feature/user-settings/team/AddMemberModal.vue'

import {
  CancelTeamInvite,
  Membership,
  RemoveTeamMember,
  SendTeamInvite,
  TeamRole,
  UpdateTeamInvite
} from '@/types/membership'
import { UserRoles, UserRolesDescription } from '@/types/user'

import { ErrorMessages, SuccessMessages } from '@/data/messages'

import ModalHandlerService from '@/services/ModalHandlerService'
import ToastNotificationService, { NotificationMessageType } from '@/services/ToastNotificationService'

import AppButtonMini from '@common/AppButtonMini.vue'
import AppDropdown from '@common/AppDropdown.vue'
import AppTooltip from '@common/AppTooltip.vue'
import Modal from '@common/Modal.vue'
import UserItem from '@feature/user-settings/team/UserItem.vue'
import UserTable from '@feature/user-settings/team/UserTable.vue'

export default defineComponent({
  name: 'MembersPanel',
  components: {
    AppButtonMini,
    AppTooltip,
    Modal,
    UserItem,
    AppDropdown,
    UserTable,
    AddMemberModal
  },
  setup() {
    const store = useStore()
    const activeMembership = computed<Membership>(() => store.getters['user/getActiveMembership'])
    const activeTeamMembers = computed(() => store.getters['teams/getActiveTeamMembers'])
    const isCurrentUserOwner = computed(() => activeMembership.value?.role === TeamRole.Owner)
    const currentUserOrgAdmin = computed(() => activeMembership.value?.role !== TeamRole.Member)

    const role = ref(null)
    const changeOwnershipLoading = ref(false)
    const newOwner = ref<Membership>()
    const usersUpdating = ref<string[]>([])
    const newPendingMembers = ref<string[]>([])
    const searchValue = ref('')
    const changeRoleOptions = ref([
      {
        label: UserRoles.OWNER,
        description: UserRolesDescription.OWNER
      },
      {
        label: UserRoles.ADMIN,
        description: UserRolesDescription.ADMIN
      },
      {
        label: UserRoles.MEMBER,
        description: UserRolesDescription.MEMBER
      },
      {
        class: 'text-agate',
        label: 'Remove from team',
        description: 'Remove member from team'
      }
    ])
    const userForRemoval = ref<Membership | null>()
    const asyncActionInProgress = ref(false)

    function disableOptions(role: UserRoles, option: string) {
      const userSelectedIsOwner = role === UserRoles.OWNER
      const removeFromTeamLabel = changeRoleOptions.value[3].label
      let isOptionDisabled = false

      if (userSelectedIsOwner) {
        if (option === removeFromTeamLabel || option === UserRoles.OWNER) {
          isOptionDisabled = true
        }
      }

      if (!isCurrentUserOwner.value) {
        if (option === UserRoles.OWNER) {
          isOptionDisabled = true
        }

        if (option === removeFromTeamLabel) {
          isOptionDisabled = true
        }
      }

      return isOptionDisabled
    }

    /* computed */
    const filteredTeamInvites = computed(() => {
      return store.getters['teams/getActiveTeamPendingInvites']
        .some((user: TeamInvite) => user.email.includes(searchValue.value))
    })

    const filteredTeamMembers = computed(() => {
      return store.getters['teams/getActiveTeamMembers']
        .some((user: Membership) => user.email.includes(searchValue.value) ||
      (user.userProfile.displayName !== undefined && user.userProfile.displayName.includes(searchValue.value)))
    })

    const showTeamMembersTable = computed(() => {
      return !filteredTeamInvites.value || filteredTeamMembers ||
      (!store.getters['teams/getActiveTeamMembers'].length && store.getters['teams/getActiveTeamPendingInvites'].length)
    })

    const hasUsers = computed(() => {
      return store.getters['teams/getActiveTeamMembers'].length || store.getters['teams/getActiveTeamPendingInvites'].length
    })

    const filteredUsers = computed(() => {
      return filteredTeamMembers.value || filteredTeamInvites.value
    })

    /* modals */
    const handleCloseTeamMemberRemovalModal = () => {
      if (userForRemoval.value) {
        store.getters['teams/getActiveTeamMembers']
          .find((user: Membership) => userForRemoval.value && user.email === userForRemoval.value.email)
          .role = userForRemoval.value.role
        userForRemoval.value = null // reset last updated user
      }
      ModalHandlerService.close()
    }

    /* invites (pending users) */
    const prepareInvitesForSending = () => {
      const pendingsUserMatchesNewMembers = store.getters['teams/getActiveTeamPendingInvites']
        .filter((user: TeamInvite) => [...newPendingMembers.value].includes(user.email))
        .map((user: TeamInvite) => { return user.email })

      const teamMembersMatchesNewMembers = store.getters['teams/getActiveTeamMembers']
        .filter((user: Membership) => [...newPendingMembers.value].includes(user.email))
        .map((user: Membership) => { return user.email })

      return [...newPendingMembers.value]
        .filter((v: string) => ![...pendingsUserMatchesNewMembers, ...teamMembersMatchesNewMembers]
          .includes(v))
    }

    const sendTeamInviteRequest = async (user?: TeamInvite) => {
      const usersNotExists = prepareInvitesForSending()
      if (user) usersUpdating.value.push(user.email)
      const userId: string = activeMembership.value.userId

      const request: SendTeamInvite = {
        userId,
        sendTeamInviteRequest: {
          users: user ? [user.email] : usersNotExists,
          teamId: store.getters['user/getActiveMembership'].teamId,
          role: user ? user.role as TeamRole : TeamRole.Member
        },
        resend: !!user
      }

      // TODO: What the fuck is this?
      const message = user ? SuccessMessages.TEAM_MEMBER_RE_INVITED : usersNotExists.length > 1
        ? SuccessMessages.TEAM_MEMBERS_INVITED : SuccessMessages.TEAM_MEMBER_INVITED
      await store.dispatch('teams/sendTeamInvite', request)
      if (user) usersUpdating.value = usersUpdating.value.filter((email: string) => email !== user.email)
      ToastNotificationService.push({ type: 'info', message })
    }

    // TODO: Why is this async?
    const handleRemoveTeamInvite = async (user: TeamInvite) => {
      const request: CancelTeamInvite = {
        teamId: store.getters['user/getActiveMembership'].teamId,
        inviteCode: user.inviteCode
      }

      return store.dispatch('teams/cancelTeamInvite', request)
    }

    // TODO: Why is this async?
    const handleUpdateTeamInvite = async (user: TeamInvite, option: string) => {
      const request: UpdateTeamInvite = {
        oldRole: user.role,
        teamId: store.getters['user/getActiveMembership'].teamId,
        inviteCode: user.inviteCode,
        updateTeamMemberRequest: {
          role: option as TeamRole
        }
      }

      return store.dispatch('teams/updateTeamInvite', request)
    }

    const handleChangeRolePendingUsers = async (user: TeamInvite, option: string) => {
      const toast = {
        message: SuccessMessages.TEAM_MEMBER_REMOVED,
        type: 'info' as NotificationMessageType
      }

      usersUpdating.value.push(user.email)
      if (option === 'Remove from team') {
        await handleRemoveTeamInvite(user)
        userForRemoval.value = null // reset last updated user
      } else {
        const response = await handleUpdateTeamInvite(user, option)
        if (response.error) {
          toast.type = 'error' as NotificationMessageType
          toast.message = response.message
        } else {
          toast.message = SuccessMessages.TEAM_MEMBER_ROLE_UPDATED
        }

        usersUpdating.value = usersUpdating.value.filter((email: string) => email !== user.email)
        ToastNotificationService.push(toast)
      }
    }

    /* prevents changing to 'Remove from team option' */
    const saveLastUpdatedPendingUser = (user: Membership, optionBeforeUpdate: { label: string, description: string}) => {
      nextTick(() => {
        store.getters['teams/getActiveTeamPendingInvites']
          .find((userStore: Membership) => userStore.email === user.email)
          .role = optionBeforeUpdate.label as TeamRole /* revert team role if remove from team selected */
      })
      userForRemoval.value = user
      return false
    }

    /* team-members */
    const handleRemoveTeamMember = async () => {
      if (userForRemoval.value?.userId === activeMembership.value.userId) {
        asyncActionInProgress.value = false
        ToastNotificationService.push({ type: 'error', message: ErrorMessages.UNSUCCESSFUL_REMOVE_FROM_ORGANISATION })
        ModalHandlerService.close()
        return
      }

      if (userForRemoval.value) {
        const request: RemoveTeamMember = {
          requesterUserId: activeMembership.value.userId,
          teamId: store.getters['user/getActiveMembership'].teamId,
          teamMemberId: userForRemoval.value.userId
        }

        asyncActionInProgress.value = true
        setTimeout(() => {
          ModalHandlerService.close()
          userForRemoval.value = null // reset last updated user
        }, 500)

        await store.dispatch('teams/removeTeamMember', request)
        asyncActionInProgress.value = false
        ToastNotificationService.push({ type: 'info', message: SuccessMessages.TEAM_MEMBER_REMOVED })
      }
    }

    const handleUpdateTeamMember = async (user: Membership, option: string) => {
      const params = {
        teamId: activeMembership.value.teamId,
        teamMemberId: user.userId,
        role: option as TeamRole
      }

      return await store.dispatch('teams/updateTeamMemberRole', params)
    }

    const handleChangeRoleTeamMembers = async (user: Membership, option: string) => {
      const toast = {
        message: SuccessMessages.TEAM_MEMBER_ROLE_UPDATED,
        type: 'info' as NotificationMessageType
      }

      const optionSelectedIsOwner = option === UserRoles.OWNER
      const isMember = user.role === UserRoles.MEMBER as string
      if (option === 'Remove from team') {
        ModalHandlerService.open('confirmation-for-removal')
        return false
      } else if (optionSelectedIsOwner) {
        if (isMember) {
          ModalHandlerService.open('set-admin-first')
        } else {
          newOwner.value = user
          ModalHandlerService.open('pass-ownership')
        }
      } else {
        usersUpdating.value.push(user.email)
        const response = await handleUpdateTeamMember(user, option)

        if (response.error) {
          toast.type = 'error' as NotificationMessageType
          toast.message = response.message
        } else {
          toast.message = SuccessMessages.TEAM_MEMBER_ROLE_UPDATED
        }

        usersUpdating.value = usersUpdating.value.filter((email: string) => email !== user.email)
        ToastNotificationService.push(toast)
      }
    }

    /* prevents chaning to 'Remove from team option' */
    const saveLastUpdatedTeamMember = (user: Membership, optionBeforeUpdate: { label: string, description: string}) => {
      nextTick(() => {
        store.getters['teams/getActiveTeamMembers']
          .find((userStore: Membership) => userStore.email === user.email)
          .role = optionBeforeUpdate.label as TeamRole /* revert team role if remove from team selected */
      })
      userForRemoval.value = user
      return false
    }

    const handleChangeOwnership = async () => {
      const toast = {
        message: SuccessMessages.TEAM_MEMBER_ROLE_UPDATED,
        type: 'info' as NotificationMessageType
      }

      changeOwnershipLoading.value = true

      await handleUpdateTeamMember(newOwner.value as Membership, UserRoles.OWNER)
      await handleUpdateTeamMember(activeMembership.value, UserRoles.ADMIN)
      changeOwnershipLoading.value = false
      ModalHandlerService.close()
      ToastNotificationService.push(toast)
    }

    return {
      ModalHandlerService,
      role,
      searchValue,
      filteredUsers,
      filteredTeamInvites,
      hasUsers,
      newOwner,
      activeTeamMembers,
      userForRemoval,
      changeRoleOptions,
      changeOwnershipLoading,
      usersUpdating,
      showTeamMembersTable,
      isCurrentUserOwner,
      asyncActionInProgress,
      currentUserOrgAdmin,
      handleCloseTeamMemberRemovalModal,
      handleChangeRoleTeamMembers,
      handleChangeRolePendingUsers,
      handleRemoveTeamMember,
      handleChangeOwnership,
      sendTeamInviteRequest,
      saveLastUpdatedTeamMember,
      saveLastUpdatedPendingUser,
      disableOptions
    }
  }
})
