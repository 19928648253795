
import { ErrorMessages, SuccessMessages } from '@/data/messages'
import { AVAILABLE_ORG_INDUSTRIES, AVAILABLE_TEAM_SIZES } from '@/data/static'
import isEmpty from '@/helpers/empty'
import router from '@/router'
import { shallowValidate } from '@/services/FormValidationService'
import ModalHandlerService from '@/services/ModalHandlerService'
import ToastNotificationService from '@/services/ToastNotificationService'
import { useStore } from '@/store'
import { IFormErrors } from '@/types/global'
import { Membership, TeamRole } from '@/types/membership'
import { TeamProfile, TeamValue } from '@/types/team'
import { computed, defineComponent, reactive, ref, toRefs, watch } from 'vue'
import inputValidationErrors from '@/data/input-validation-errors'

import AccountPictureUpload from '@/components/feature/user-settings/personal/AccountPictureUpload.vue'
import AppDropdown from '@common/AppDropdown.vue'
import Modal from '@common/Modal.vue'

export default defineComponent({
  name: 'TeamSettingsPanel',
  components: {
    AppDropdown,
    AccountPictureUpload,
    Modal
  },
  setup() {
    const store = useStore()

    const activeMembership = computed<Membership>(() => store.getters['user/getActiveMembership'])
    const userIsOwner = computed(() => store.getters['user/getActiveTeamRole'] === TeamRole.Owner)
    // TODO CHECK IF NEEDED
    const activeTeamValues = computed<TeamValue[]>(() => store.getters['teams/getActiveTeamValues'])

    const isDeleting = ref(false)
    const isSaving = ref(false)

    const errors = reactive<IFormErrors>({
      fieldEmptyErrors: {},
      specialErrors: {}
    })

    const teamDescriptionError = computed(() => isEmpty(errors.specialErrors) ? false : inputValidationErrors.teamDescription.maxLength)

    const fields = reactive({
      name: activeMembership.value?.teamProfile.name,
      industry: activeMembership.value?.teamProfile.industry,
      size: activeMembership.value?.teamProfile.size
    })

    watch(activeMembership, (activeMembership) => {
      fields.name = activeMembership?.teamProfile.name
      fields.industry = activeMembership?.teamProfile.industry
      fields.size = activeMembership?.teamProfile.size
    }, { immediate: true })

    async function deleteTeam() {
      isDeleting.value = true

      try {
        await store.dispatch('teams/deleteTeam', {
          teamId: activeMembership.value.teamId
        })

        await router.push('/')
        document.body.scrollIntoView()
        ToastNotificationService.push({ type: 'info', message: SuccessMessages.TEAM_DELETED })
        const memberships = store.getters['user/getMemberships']
        if (memberships?.length) {
          await store.dispatch('user/switchActiveTeam', { teamId: memberships[0].teamId, isNewTeam: false })
        }
      } catch (error) {
        if (error instanceof Error && error) {
          ToastNotificationService.push({ type: 'error', message: ErrorMessages.UNSUCCESSFUL_DELETE_TEAM })
          console.info('There has been an error with deleting the team,', 'Reason:', error.message)
        }
      } finally {
        isDeleting.value = false
        ModalHandlerService.close()
      }
    }

    async function saveChanges() {
      errors.fieldEmptyErrors = shallowValidate({ teamName: fields.name || '' })

      if (isEmpty(errors.fieldEmptyErrors) && isEmpty(errors.specialErrors)) {
        isSaving.value = true
        try {
          await store.dispatch('teams/updateTeam', {
            teamId: activeMembership.value.teamId,
            profile: new TeamProfile(
              fields.name,
              activeMembership.value?.teamProfile.pictureId,
              fields.industry,
              fields.size,
              undefined
            ),
            values: activeTeamValues.value
          })
          ToastNotificationService.push({ type: 'info', message: SuccessMessages.ORGANISATION_DETAILS_UPDATED })
        } catch (error) {
          if (error instanceof Error) {
            ToastNotificationService.push({ type: 'error', message: error.message })
          }
        } finally {
          isSaving.value = false
        }
      }
    }

    return {
      ...toRefs(errors),
      ...toRefs(fields),
      activeMembership,
      ModalHandlerService,
      isSaving,
      deleteTeam,
      userIsOwner,
      teamDescriptionError,
      AVAILABLE_ORG_INDUSTRIES,
      AVAILABLE_TEAM_SIZES,
      saveChanges
    }
  }
})
