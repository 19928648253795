
import { defineComponent } from 'vue'
import ModalHandlerService from '@/services/ModalHandlerService'

export default defineComponent({
  name: 'CreateTeamPanel',
  props: {
    borderless: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { ModalHandlerService }
  }
})
