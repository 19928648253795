import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "user-item grid align-items-center" }
const _hoisted_2 = { class: "user-item__details" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "user-item__teams-trigger" }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "user-item__teams-trigger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAvatar = _resolveComponent("AppAvatar")!
  const _component_AppBasicDropdown = _resolveComponent("AppBasicDropdown")!
  const _component_AppDropdown = _resolveComponent("AppDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["user-item__left", [_ctx.isGroup ? 'col-8 col-md-9' : 'col-5 col-md-6']])
    }, [
      _createVNode(_component_AppAvatar, {
        class: "user-item__avatar",
        user: _ctx.userProfile,
        size: _ctx.user.inviteCode ? 'small' : 'normal'
      }, null, 8, ["user", "size"]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.userProfile.firstName && _ctx.userProfile.lastName)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              textContent: _toDisplayString(`${_ctx.userProfile.firstName} ${_ctx.userProfile.lastName}`),
              class: "user-item__full-name"
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          class: "user-item__email",
          textContent: _toDisplayString(_ctx.user.email)
        }, null, 8, _hoisted_4)
      ])
    ], 2),
    (!_ctx.isGroup && _ctx.user.teamProfile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["user-item__teams", [
        _ctx.twoColumnsLayout ? 'col-6 user-item__teams--end' : 'col-3'
      ]])
        }, [
          (_ctx.groups.length)
            ? (_openBlock(), _createBlock(_component_AppDropdown, { key: 0 }, {
                handler: _withCtx((props) => [
                  _createElementVNode("button", {
                    onClick: props.visibility.toggle
                  }, [
                    _createElementVNode("span", _hoisted_6, [
                      _createTextVNode(_toDisplayString(_ctx.groups.length) + " ", 1),
                      _createElementVNode("span", {
                        textContent: _toDisplayString(_ctx.groups.length > 1 ? 'teams' : 'team')
                      }, null, 8, _hoisted_7)
                    ]),
                    _createElementVNode("span", _hoisted_8, [
                      _createTextVNode(_toDisplayString(_ctx.groups[0].name) + " ", 1),
                      (_ctx.groups.length > 1)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createTextVNode("+ " + _toDisplayString(_ctx.groups.length - 1), 1)
                          ], 64))
                        : _createCommentVNode("", true)
                    ])
                  ], 8, _hoisted_5)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_AppBasicDropdown, {
                    class: _normalizeClass(_ctx.groups.length > 7 && 'app-basic-dropdown--top')
                  }, {
                    items: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group, index) => {
                        return (_openBlock(), _createElementBlock("p", {
                          class: "user-item__teams-dropdown-item",
                          key: index
                        }, _toDisplayString(_ctx.truncate(group.name, 22)), 1))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["user-item__slot", [
      _ctx.user.teamProfile && 'col-4 col-md-3',
      !_ctx.user.teamProfile && 'col-6 offset-6 offset-md-0'
    ]])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}