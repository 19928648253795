
import { defineComponent } from 'vue'
import Modal from '@common/Modal.vue'

export default defineComponent({
  name: 'DisableSlackModal',
  emits: ['slack:disconnect', 'slack:cancel'],
  components: {
    Modal
  },
  props: {
    loading: {
      type: Boolean,
      required: false
    }
  }
})
