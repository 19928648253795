
import { computed, defineComponent, reactive, ref, toRefs, nextTick } from 'vue'
import { useStore } from '@/store'

import { User, UserProfile } from '@/types/user'

import { SuccessMessages } from '@/data/messages'
import { IFormErrors } from '@/types/global'

import isEmpty from '@/helpers/empty'

import FormValidationService, { shallowValidate } from '@/services/FormValidationService'
import ToastNotificationService from '@/services/ToastNotificationService'

import AccountPictureUpload from './AccountPictureUpload.vue'

import AppDropdown from '@common/AppDropdown.vue'

export default defineComponent({
  name: 'AccountPanel',
  components: {
    AccountPictureUpload,
    AppDropdown
  },
  setup: () => {
    const store = useStore()

    const user = computed<User>(() => store.getters['user/getUser'])
    const loading = ref(false)
    const fields = reactive({
      firstName: user.value.profile.firstName,
      lastName: user.value.profile.lastName,
      email: user.value.email,
      displayName: user.value.profile.displayName
    })

    const userTheme = ref(getTheme() || 'light-theme')
    const themeOptions = ref(['light-theme', 'dark-theme'])

    const errors = reactive<IFormErrors>({
      fieldEmptyErrors: {},
      specialErrors: {}
    })

    function getTheme() {
      return localStorage.getItem('user-theme')
    }

    function setTheme() {
      localStorage.setItem('user-theme', userTheme.value)
      document.documentElement.className = userTheme.value
    }

    async function saveChanges() {
      errors.fieldEmptyErrors = shallowValidate({ firstName: fields.firstName || '', lastName: fields.lastName || '' })
      errors.specialErrors = FormValidationService(fields)

      if (isEmpty(errors.fieldEmptyErrors) && isEmpty(errors.specialErrors)) {
        loading.value = true

        try {
          await store.dispatch('user/updateUser', {
            profile: new UserProfile(
              fields.displayName,
              fields.firstName,
              fields.lastName
            )
          })

          ToastNotificationService.push({
            type: 'info',
            message: SuccessMessages.ACCOUNT_DETAILS_UPDATED
          })
        } catch (error) {
          errors.specialErrors = Object.assign(errors.specialErrors, {
            aws: {
              err: error instanceof Error && error.message
            }
          })
        } finally {
          loading.value = false
        }
      }
    }

    return {
      loading,
      ...toRefs(fields),
      ...toRefs(errors),
      saveChanges,
      userTheme,
      themeOptions,
      setTheme
    }
  }
})
