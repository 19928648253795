
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'AppTooltip',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const visible = ref(false)

    function updateVisibility(value: boolean): void {
      visible.value = value
    }

    return {
      visible,
      updateVisibility
    }
  }
})
